<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("kab.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <h6 class="ssp-24">{{ $t("kab.title") }}</h6>
            <!-- <button class="btn btn-success btn-add ml-auto mr-4" @click="onAdd">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              Tambah Data Provinsi
            </button> -->
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('kab.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Role">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="role in optionRole1"
                        :key="role.id"
                      >
                        <label class="form-radio-label">
                          <input
                            class="form-radio-item"
                            type="radio"
                            :value="role"
                            v-model="filterRole"
                          />
                          {{ role }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRole === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="dataKabupatens"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('kab.headers[1]'),
                  key: 'kabupaten',
                  sortable: true
                },
                {
                  label: $t('kab.headers[2]'),
                  key: 'province',
                  sortable: true
                }
              ]"
              sort-icon-left
              show-empty
              small
              responsive="true"
            >
              <!-- <template v-slot:head(selected)>
                <input type="checkbox" />
              </template>
              <template v-slot:cell(selected)="id">
                <div @click.stop>
                  <input type="checkbox" />
                </div>
              </template> -->

              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++data.index + (currPage - 1) * perPage }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(provinsi)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.province }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(province)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{
                      data.item.provinces ? data.item.provinces.province : "-"
                    }}
                  </li>
                  <br />
                </ul>
              </template>

              <!-- <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-edit-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i>
                  </div>
                </ul>
              </template> -->
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-region" centered hide-footer>
      <template #modal-header="{}">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <!-- <div class="form-group">
            <label for="nama-region" class="ssp-16 text-black"
              >Nama Region <span class="text-red">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.nama_region.$error }"
              id="nama-region"
              v-model="$v.formTambah.nama_region.$model"
              placeholder="Masukkan nama region disini"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.nama_region.$error"
            >
              <div>Nama region tidak boleh kosong</div>
            </div>
          </div> -->
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("province.headers[1]") }}
            </label>
            <!-- <multiselect placeholder="Pilih Country" v-model="formTambah.id_country"
              :options="dataCountry.map(c => c.id)" :custom-label="opt => dataCountry.find(x => x.id == opt).country">
            </multiselect> -->
            <multiselect
              :class="{
                'invalid-form rounded-input': $v.formTambah.province_id.$error
              }"
              :placeholder="$t('retailers.placeholder.province')"
              v-model="formTambah.province_id"
              :options="dataProvinces"
              label="province"
            >
            </multiselect>
            <!-- {{ formTambah.province_id }}
            ---
             -->
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.province_id.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";

import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import {
  listKabupatens,
  // M_ADD_REGION,
  // updateKabupaten,
  // M_DEL_REGION,
  Q_LIST_PROVINCE
} from "@/graphql/super_admin";
import jwtService from "@/core/services/jwt.service";
import Axios from "axios";
// import * as XLSX from "xlsx";

export default {
  components: {
    Card,
    multiselect: Multiselect
  },
  data() {
    return {
      fields: [
        {
          label: "#",
          key: "index"
        },
        {
          label: "Kabupaten Name",
          key: "kabupaten",
          sortable: true
        },
        {
          label: "Province Name",
          key: "province",
          sortable: true
        }
      ],
      dataKabupatens: [],
      dataProvinces: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      modalTitle: "",
      isEdit: false,

      formTambah: {
        id_region: "",
        id_country: "",
        nama_region: ""
      }
    };
  },
  validations: {
    formTambah: {
      // re: { required },
      province_id: { required }
    }
  },
  apollo: {
    listKabupaten: {
      query: () => listKabupatens,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          province_id: ""
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataKabupatens = data.listKabupaten.kabupatens;
        this.totalPages = data.listKabupaten.totalPage;
        this.totalData = data.listKabupaten.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listProvince: {
      query: () => Q_LIST_PROVINCE,
      variables() {
        return {
          // page: 1,
          // limit: 9,
          keyword: "",
          region_id: ""
        };
      },
      result({ data }) {
        this.dataProvinces = data.listProvince.provinces;
      }
    }
  },
  methods: {
    // fetchCountry() {
    //   return new Promise((resolve, reject) => {
    //     const result = this.$apollo.query({
    //       query: Q_LIST_COUNTRY
    //     });
    //     if (resolve) {
    //       resolve(result);
    //     } else {
    //       reject(result);
    //     }
    //   })
    //     .then(response => {
    //       this.dataCountry = response.data.listCountry.countrys;
    //     })
    //     .catch(err => {
    //       alert(err.message);
    //     });
    // },
    onAdd() {
      this.modalTitle = "Tambah Country";
      this.isEdit = false;
      this.resetForm();
      this.fetchCountry();
      this.$bvModal.show("modal-region");
    },
    closeModal() {
      this.$bvModal.hide("modal-region");
    },
    resetForm() {
      this.formTambah.id = "";
      this.formTambah.region_id = "";
      this.$v.$reset();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    onEdit(data) {
      this.modalTitle = "Ubah Kabupaten";
      this.isEdit = true;
      this.resetForm();
      // this.fetchCountry();

      this.formTambah.id = data.id;
      this.formTambah.province_id = data.provinces;

      this.$bvModal.show("modal-region");
    },
    // async onSubmit(ev) {
    //   ev.preventDefault();
    //   this.$v.$touch();
    //   if (!this.$v.$error) {
    //     if (this.isEdit) {
    //       return new Promise((resolve, reject) => {
    //         const editProvince = this.$apollo.mutate({
    //           mutation: updateKabupaten,
    //           variables: {
    //             id: this.formTambah.id,
    //             province_id: this.formTambah.province_id
    //               ? this.formTambah.province_id.id
    //               : ""
    //           }
    //         });
    //         if (resolve) {
    //           resolve(editProvince);
    //         } else {
    //           reject(editProvince);
    //         }
    //       })
    //         .then(response => {
    //           if (response) {
    //             this.toastAlert(this.$t("alert.edit"));
    //             this.closeModal();
    //             this.$apollo.queries.listKabupaten.refetch();
    //           }
    //         })
    //         .catch(err => {
    //           this.$swal.fire({
    //             icon: "error",
    //             title: "Error!",
    //             text: err.message,
    //             width: 400,
    //             confirmButtonText: "OK"
    //           });
    //         });
    //     } else {
    //       return new Promise((resolve, reject) => {
    //         const addRegion = this.$apollo.mutate({
    //           mutation: M_ADD_REGION,
    //           variables: {
    //             country_id: parseInt(this.formTambah.id_country.id),
    //             region: this.formTambah.nama_region
    //           }
    //         });
    //         if (resolve) {
    //           resolve(addRegion);
    //         } else {
    //           reject(addRegion);
    //         }
    //       })
    //         .then(response => {
    //           if (response) {
    //             this.toastAlert(this.$t("alert.save"));
    //             this.closeModal();
    //             this.$apollo.queries.listKabupaten.refetch();
    //           }
    //         })
    //         .catch(err => {
    //           this.errorAlert(err);
    //         });
    //     }
    //   }
    // },
    // onDelete(id) {
    //   this.deleteConfirm().then(async result => {
    //     if (result.isConfirmed) {
    //       return new Promise((resolve, reject) => {
    //         const delRegion = this.$apollo.mutate({
    //           mutation: M_DEL_REGION,
    //           variables: {
    //             id: id
    //           }
    //         });
    //         if (resolve) {
    //           resolve(delRegion);
    //         } else {
    //           reject(delRegion);
    //         }
    //       })
    //         .then(response => {
    //           if (response) {
    //             this.toastAlert(this.$t("alert.delete"));
    //             this.$apollo.queries.listKabupaten.refetch();
    //           }
    //         })
    //         .catch(err => {
    //           this.errorAlert(err);
    //         });
    //     }
    //   });
    // },
    async handleExport() {
      this.isLoading = true;
      Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/kabupaten/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + jwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `kabupaten-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    // this.fetchRegion()
  }
};
</script>

<style>
.region-table thead {
  background-color: #fafafa;
  border-radius: 50px;
}

.region-table tbody tr td {
  /* background-color: red; */
  padding-top: 0.8rem;
  padding-bottom: 0.9rem;
  /* vertical-align: middle; */
}

.region-table tbody tr td ul {
  margin-bottom: 0;
}

.region-table thead tr th:first-child {
  width: 5%;
}

.region-table thead tr th:nth-child(2) {
  width: 25%;
}

.region-table thead tr th:nth-child(3) {
  width: 50%;
}
</style>
